import styled, { css } from "styled-components"
import { useLocale } from "react-polyglot-hooks"
import * as PropTypes from "prop-types"
import CarPreOwned from "components/icons/car-pre-owned"
import VanCar from "components/icons/van-car"
import { mq } from "helpers/styled"
import CompactCar from "components/icons/compact-car"
import SuvCar from "components/icons/suv-car"
import Newcar from "components/icons/new-car"
import CompanyCars from "components/icons/company-cars"
import { useCallback } from "react"
import { sendEvent } from "scripts/GoogleAnalytics"
import Suggestion from "./Suggestion"

const Grid = styled.div`
  display: grid;
  gap: 25px min(40px, calc(15px + 3%));
  gap: 25px 10px;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(2, minmax(10px, auto));
  justify-items: center;
  justify-content: space-between;
  margin: 35px auto 0;
  max-width: 600px;

  ${({ count }) =>
    count > 4 &&
    css`
      @media ${mq("lg")} {
        grid-template-columns: repeat(${count / 2}, minmax(10px, auto));
        max-width: 875px;
      }
    `}

  ${({ altStyle }) =>
    altStyle &&
    css`
      @media ${mq("xl")} {
        max-width: 850px;
      }
    `};
`

const Container = styled.div`
  margin-top: 75px;
  margin: auto;
  text-align: center;
`

function Suggestions({ page, types, altStyle }) {
  const locale = useLocale()

  const handleClick = useCallback(
    variant => {
      sendEvent("Click renting type", { event_category: page, event_label: variant })
    },
    [page]
  )

  if (!types.length) return null

  return (
    <Container>
      <Grid count={types.length} altStyle={altStyle}>
        {types.includes("new") && (
          <Suggestion variant="new" page="stock" icon={Newcar} altStyle={altStyle} handleClick={handleClick} />
        )}
        {types.includes("commercial") && (
          <Suggestion
            variant="commercial"
            page="commercial"
            icon={VanCar}
            altStyle={altStyle}
            handleClick={handleClick}
          />
        )}
        {["es", "pt"].includes(locale) && types.includes("pre_owned") && (
          <Suggestion
            variant="pre_owned"
            page="pre-owned"
            icon={CarPreOwned}
            altStyle={altStyle}
            handleClick={handleClick}
          />
        )}
        {types.includes("employee") && (
          <Suggestion variant="employee" page="employee" icon={SuvCar} altStyle={altStyle} handleClick={handleClick} />
        )}
        {types.includes("freelance") && (
          <Suggestion
            variant="freelance"
            page="freelance"
            icon={CompactCar}
            altStyle={altStyle}
            handleClick={handleClick}
          />
        )}
        {types.includes("company") && (
          <Suggestion
            variant="company"
            page="company"
            icon={CompanyCars}
            altStyle={altStyle}
            handleClick={handleClick}
          />
        )}
      </Grid>
    </Container>
  )
}

Suggestions.defaultProps = {
  types: [],
  altStyle: false
}

Suggestions.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string),
  altStyle: PropTypes.bool,
  page: PropTypes.string.isRequired
}

export default Suggestions
