import * as PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { useT } from "react-polyglot-hooks"
import { SecondaryButton } from "components/styled/Buttons/SecondaryButton"
import { color, mq } from "helpers/styled"
import Typography from "components/styled/Typography"
import Icon from "components/icons/icon"
import Link from "components/Link"

const Header = styled.div``

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Action = styled.div`
  width: 100%;
  padding-top: 25px;
  margin-top: auto;
`

const Button = styled(SecondaryButton)`
  display: flex;
  align-items: center;
  width: min(120px, 100%);
  height: 38px;
  font-size: 12px;

  @media ${mq("md")} {
    width: 175px;
  }
`

const SuggestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: min(275px, 100%);
  padding: 5px 15px 15px;
  background: white;
  text-align: center;
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 ${color("lightGray")};

  @media ${mq("md")} {
    padding: 5px 50px 15px;
  }

  ${({ altStyle }) =>
    altStyle &&
    css`
      @media ${mq("xl")} {
        flex-direction: row;
        padding: 0 !important;
        max-width: 386px;

        ${Header} {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          padding: 0;
          width: 156px;
          height: 140px;
          margin: 5px;
          border-radius: 6px;
          background: ${color("paleGray")};
        }

        ${Title} {
          min-height: none;
          margin: 0;
          font-size: 18px;
          font-weight: 600;
        }

        ${Body} {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          padding: 15px 25px 20px;

          ${Typography} {
            margin: auto;
          }
        }
      }
    `};
`

const Title = styled(Typography)`
  margin-bottom: 5px;
  color: ${color("skyBlue")};
  min-height: 40px;
`

function Suggestion({ variant, page, icon, altStyle, handleClick }) {
  const t = useT()

  return (
    <SuggestionWrapper altStyle={altStyle}>
      <Header>
        <Icon Icon={icon} size="xxl" color="darkBlue" />
        <Title el="h3">{t(`landings.kind.${variant}.title`)}</Title>
      </Header>
      <Body>
        <Typography variant="small">{t(`landings.kind.${variant}.description`)}</Typography>
        <Action>
          <Link page={page}>
            <Button as="a" border onClick={() => handleClick(page)}>
              {t("common.view_offers")}
            </Button>
          </Link>
        </Action>
      </Body>
    </SuggestionWrapper>
  )
}

Suggestion.defaultProps = {
  altStyle: false
}

Suggestion.propTypes = {
  variant: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  altStyle: PropTypes.bool,
  handleClick: PropTypes.func.isRequired
}

export default Suggestion
