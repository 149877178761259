export default function CompanyCars() {
  return (
    <g fill="none" fillRule="evenodd">
      <path
        fill="#021859"
        d="M19.46 23.495c2.013 0 3.65 1.684 3.65 3.752 0 2.07-1.637 3.753-3.65 3.753-2.012 0-3.648-1.683-3.648-3.753 0-2.068 1.636-3.752 3.649-3.752zm20.908 0c2.012 0 3.649 1.684 3.649 3.752 0 2.07-1.637 3.753-3.649 3.753s-3.65-1.683-3.65-3.753c0-2.068 1.638-3.752 3.65-3.752zM19.461 24.66c-1.388 0-2.517 1.161-2.517 2.587 0 1.428 1.13 2.588 2.517 2.588s2.516-1.16 2.516-2.588c0-1.426-1.129-2.587-2.516-2.587zm20.907 0c-1.387 0-2.517 1.161-2.517 2.587 0 1.428 1.13 2.588 2.517 2.588 1.388 0 2.516-1.16 2.516-2.588 0-1.426-1.128-2.587-2.516-2.587zm-5.404 1.667c.312 0 .566.26.566.582a.574.574 0 01-.566.582H24.607a.574.574 0 01-.566-.582c0-.322.253-.582.566-.582zM14.88 13.66c5.478-1.188 15.919-.416 16.36-.383a.552.552 0 01.196.051c1.828.863 4.742 3.678 5.505 4.433 4.725-.276 9.528 2.12 9.733 2.224.194.097.317.3.317.522v2.391c.59.234 1.009.824 1.009 1.512v1.461c0 .895-.705 1.62-1.575 1.62h-.987a.575.575 0 01-.562-.642c.029-.303.299-.522.594-.522h.954a.45.45 0 00.444-.456v-1.46a.45.45 0 00-.432-.456.584.584 0 01-.578-.583V20.88c-1.153-.526-5.255-2.245-9.087-1.94a.555.555 0 01-.44-.166c-.034-.034-3.367-3.385-5.278-4.343-1.102-.077-10.615-.705-15.713.322-.803 1.25-2.004 3.232-2.17 3.804-.09.317-.147 2.35-.016 7.045a.736.736 0 00.725.725h.547c.332 0 .598.293.564.642a.595.595 0 01-.596.522h-.515c-1.01.028-1.85-1.58-1.86-1.979l-.001-.011c-.024-.321-.082-7.286.322-7.89.612-1.264 2.002-3.42 2.186-3.704a.569.569 0 01.355-.247zm15.628 7.365c.314 0 .566.26.566.582a.574.574 0 01-.566.583h-2.007a.575.575 0 01-.566-.583c0-.322.253-.582.566-.582zm-3.276-5.912a.565.565 0 01.696.407l.709 2.662h5.688c.313 0 .567.26.567.582a.574.574 0 01-.567.582H14.662a.574.574 0 01-.566-.582c0-.321.254-.582.566-.582h5.398l.743-2.668a.566.566 0 01.7-.4c.301.089.475.411.39.72l-.655 2.348h6.224l-.627-2.355a.584.584 0 01.397-.714z"
      />
      <path
        fill="#fff"
        d="M33.265 26.659c-1.861-1.684-6.657-2.06-7.69-2.121-1.974-1.748-5.124-3.034-6.06-3.392a2.19 2.19 0 00-.792-.146h-8.388a2.17 2.17 0 00-.714.116c-2.159.753-4.176 2.175-4.724 2.577H3.42c-.914 0-1.658.705-1.658 1.57v.79A1.41 1.41 0 001 27.283v3.12c0 .696.548 1.296 1.275 1.396l.937.13c.076.044.165.07.261.07l23.034-.004c.019 0 4.123.002 4.141 0l2.435.004c.674 0 1.255-.244 1.595-.67.232-.292.458-.8.223-1.582-.234-.775-.67-2.216-1.635-3.088z"
      />
      <path
        fill="#021859"
        d="M28.405 28.243c1.898 0 3.443 1.515 3.443 3.378 0 1.863-1.545 3.378-3.443 3.378-1.897 0-3.442-1.515-3.442-3.378 0-1.863 1.545-3.378 3.442-3.378zm-20.813 0c1.898 0 3.443 1.515 3.443 3.378 0 1.863-1.545 3.378-3.443 3.378-1.897 0-3.442-1.515-3.442-3.378 0-1.863 1.545-3.378 3.442-3.378zm20.812 1.048c-1.308 0-2.373 1.045-2.373 2.33 0 1.284 1.065 2.33 2.374 2.33 1.309 0 2.374-1.046 2.374-2.33 0-1.285-1.065-2.33-2.374-2.33zm-20.812 0c-1.309 0-2.374 1.045-2.374 2.33 0 1.284 1.065 2.33 2.374 2.33 1.309 0 2.374-1.046 2.374-2.33 0-1.285-1.065-2.33-2.374-2.33zm15.915 2.73a.53.53 0 01.534.524.53.53 0 01-.534.525h-11.06a.53.53 0 01-.535-.525c0-.29.24-.525.534-.525zm-4.74-11.02c.288 0 .57.052.837.158.99.395 4.326 1.805 6.418 3.722 1.093.069 6.17.48 8.141 2.328 1.023.958 1.483 2.54 1.73 3.389.25.858.01 1.416-.235 1.735-.36.468-.975.737-1.688.737h-.557a.53.53 0 01-.534-.525c0-.29.239-.524.534-.524h.557c.37 0 .682-.12.835-.32.144-.187.165-.457.065-.803-.223-.765-.632-2.17-1.445-2.93-1.562-1.465-6.056-1.976-7.655-2.05a.539.539 0 01-.348-.148c-1.94-1.854-5.25-3.253-6.219-3.638a1.177 1.177 0 00-.437-.083H9.883c-.14 0-.27.02-.387.063-2.458.889-4.825 2.757-4.848 2.775a.544.544 0 01-.336.117h-1.75a.68.68 0 00-.686.674v1.21a.525.525 0 01-.342.488l-.14.053a.503.503 0 00-.326.466v3.003c0 .246.187.46.435.495l1.312.188a.525.525 0 01.45.595.533.533 0 01-.605.442l-1.312-.187C.58 32.32 0 31.66 0 30.898v-3.003c0-.561.316-1.08.807-1.35v-.867c0-.95.787-1.722 1.755-1.722h1.564c.58-.443 2.717-2.003 5.002-2.828.236-.085.49-.128.755-.128zm.4 6.404c.294 0 .534.234.534.523a.53.53 0 01-.534.525h-1.894a.53.53 0 01-.534-.525c0-.289.24-.524.534-.524zm-4.859-4.685a.53.53 0 01.534.524v2.546a.53.53 0 01-.534.525H9.434a.533.533 0 01-.19-.035l-2.551-.955a.522.522 0 01-.31-.677.54.54 0 01.69-.303l2.46.921h4.241v-2.022a.53.53 0 01.534-.525zm1.956 0a.53.53 0 01.534.524v1.839h6.285a.53.53 0 01.534.525.53.53 0 01-.534.523h-6.819a.53.53 0 01-.534-.524v-2.362c0-.29.24-.525.534-.525z"
      />
    </g>
  )
}
