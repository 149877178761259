import PropTypes from "prop-types"
import { useT } from "react-polyglot-hooks"
import { Container, Section, TitleWrapper } from "components/styled/Grid"
import Typography from "components/styled/Typography"
import Suggestions from "components/Suggestions"

export default function Kinds({ page }) {
  const t = useT()

  return (
    <Section bottom={50}>
      <Container>
        <TitleWrapper>
          <Typography variant="title2" weight="medium" center space>
            {t("landings.kind.title")}
          </Typography>
          <Typography center space>
            {t("landings.kind.subtitle")}
          </Typography>
        </TitleWrapper>
        <Suggestions types={["new", "commercial", "employee", "freelance", "company", "pre_owned"]} page={page} />
      </Container>
    </Section>
  )
}

Kinds.propTypes = {
  page: PropTypes.string.isRequired
}
