import { useRef } from "react"
import * as PropTypes from "prop-types"
import dynamic from "next/dynamic"
import LazyLoad from "react-lazyload"
import GlobalModals from "components/Modals/GlobalModals"
import ModalContextProvider from "components/Modals/GlobalModals/ModalContext"

const TopBar = dynamic(() => import("../TopBar"))
const CallCta = dynamic(() => import("../CallCta"))
const Footer = dynamic(() => import("../Footer"))
// const Cookies = dynamic(() => import("../Cookies"), { ssr: false })
const FloatingCta = dynamic(() => import("../FloatingCta"), { ssr: false })

export default function DefaultLayout({ children, white, page, floatingCall, floatingButton, showCta, grayCta }) {
  const ctaRef = useRef(null)

  return (
    <ModalContextProvider>
      <TopBar white={white} page={page} floating={floatingCall} grayCta={grayCta} />
      {children}
      <LazyLoad height={500} once offset={100}>
        {showCta && <CallCta ctaRef={ctaRef} page={page} />}
        <Footer page={page} />
      </LazyLoad>
      {floatingButton && <FloatingCta page={page} ctaRef={ctaRef} />}
      <GlobalModals />
      {/* <Cookies /> */}
    </ModalContextProvider>
  )
}

DefaultLayout.defaultProps = {
  white: false,
  grayCta: false,
  floatingCall: false,
  floatingButton: false,
  showCta: true
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  white: PropTypes.bool,
  grayCta: PropTypes.bool,
  floatingCall: PropTypes.bool,
  floatingButton: PropTypes.bool,
  showCta: PropTypes.bool,
  page: PropTypes.string.isRequired
}
